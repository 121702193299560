<template>
  <section class="hero" id="about">
    <div class="hero-content">
      <h1>Hi, I'm <span class="highlight">{{ name }}</span></h1>
      <div class="typing-text">{{ currentText }}</div>
      <p class="bio">{{ bio }}</p>
      <div class="social-links">
        <a v-for="link in socialLinks" 
           :key="link.name" 
           :href="link.url" 
           class="social-link"
           target="_blank">
          {{ link.icon }}
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted } from 'vue'

export default {
  name: 'HeroSection',
  props: {
    name: {
      type: String,
      required: true
    },
    titles: {
      type: Array,
      required: true
    },
    bio: {
      type: String,
      required: true
    },
    socialLinks: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const currentText = ref('')
    let currentTitleIndex = 0
    let currentCharIndex = 0
    let isDeleting = false
    let typingSpeed = 100

    const typeText = () => {
      const currentTitle = props.titles[currentTitleIndex]
      
      if (isDeleting) {
        currentText.value = currentTitle.substring(0, currentCharIndex - 1)
        currentCharIndex--
        typingSpeed = 50
      } else {
        currentText.value = currentTitle.substring(0, currentCharIndex + 1)
        currentCharIndex++
        typingSpeed = 100
      }

      if (!isDeleting && currentCharIndex === currentTitle.length) {
        isDeleting = true
        typingSpeed = 1000
      } else if (isDeleting && currentCharIndex === 0) {
        isDeleting = false
        currentTitleIndex = (currentTitleIndex + 1) % props.titles.length
      }

      setTimeout(typeText, typingSpeed)
    }

    onMounted(() => {
      typeText()
    })

    return {
      currentText
    }
  }
}
</script>

<style scoped>
.hero {
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding-top: 4rem;
}

.hero-content {
  max-width: 600px;
}

.hero h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.highlight {
  color: #60a5fa;
}

.typing-text {
  font-size: 1.5rem;
  color: #94a3b8;
  height: 2em;
}

.bio {
  margin: 2rem 0;
}

.social-links {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}


.social-link {
  color: #a9a9a9; /* Initial color */
  transition: color 0.3s ease, transform 0.3s ease;
  font-size: 1.5rem;
}

.social-link:hover {
  color: #60a5fa; /* Change color on hover */
  transform: scale(1.1); /* Slight zoom effect on hover */
}


@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }
}
</style>