<template>
  <nav>
    <div class="nav-content">
      <div class="logo">{{ portfolio.name.split(' ')[0] }}</div>
      <div class="nav-links" v-bind:class="{ active: showMenu }">
        <a href="#about">About</a>
        <a href="#projects">Projects</a>
        <a href="#skills">Skills</a>
        <a href="#contact">Contact</a>
      </div>
      <div class="hamburger" @click="toggleMenu">
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
      </div>
    </div>
  </nav>

  <div class="container">
    <Hero
      :name="portfolio.name"
      :titles="portfolio.titles"
      :bio="portfolio.bio"
      :social-links="portfolio.socialLinks"
    />
    
    <Projects :projects="portfolio.projects" />
    
    <section id="skills">
      <h2 class="section-title">Skills & Technologies</h2>
      <div class="skills-container">
        <span v-for="skill in portfolio.skills" 
              :key="skill" 
              class="skill-tag">
          {{ skill }}
        </span>
      </div>
    </section>

    <footer id="contact">
      <div class="footer-content">
        <h2 class="section-title">Let's Connect</h2>
        <p class="footer-text">I'm always open to new opportunities and collaborations.</p>
        <p class="footer-contact">
          &copy; 2024 Tadas Šolys | <a href="mailto:tadassolys@gmail.com">Contact Me</a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import Hero from './components/Hero.vue'
import Projects from './components/Projects.vue'
import { ref } from 'vue';

export default {
  name: 'App',
  components: {
    Hero,
    Projects
  },
  setup() {
    const portfolio = {
      name: 'Tadas Šolys',
      titles: [
        'Full Stack Developer',
        'Android Developer',
        'AI training Enthusiast'
      ],
      bio: "Hi, I'm Tadas Šolys, a recent graduate with a Bachelor's degree in Software Development. I'm passionate about coding, solving problems, and building innovative software solutions. Below are some of the projects I’ve worked on, feel free to check them out!",
      socialLinks: [
        { name: 'GitHub', url: 'https://github.com/tadassolys', icon: '📦' },
        { name: 'LinkedIn', url: 'https://www.linkedin.com/in/tadas-%C5%A0olys-8a2418328/', icon: '💼' }
      ],
      projects: [
        {
          title: 'Landmark Recognition',
          description: 'Android application that recognizes landmarks using machine learning.',
          technologies: ['Kotlin', 'Python', 'TensorFlow', 'Machine Learning'],
          url: 'https://github.com/tadassolys/Lankytinu_objektu_atpazinimas'
        },
        {
          title: 'A fullstack Reservation Management System',
          description: 'Real-time room, equipment, and vehicle reservations.',
          technologies: ['Vue 3', 'Laravel', 'MySQL'],
          url: 'https://github.com/tadassolys/Rezervis_frontend'
        },
        {
          title: 'Android password manager',
          description: 'Android application that uses SQLCipher for encryption, allowing users to manage their credentials safely.',
          technologies: ['Java', 'SQL Lite'],
          url: 'https://github.com/tadassolys/Passman'
        }
      ],
      skills: [
        'Java',
        'Kotlin',
        'JavaScript',
        'Node.js',
        'SpringBoot',
        'Laravel',
        'MySQL',
        'PostgreSQL',
        'Docker',
        'Git',
        'REST APIs',
      ]
    }

    const showMenu = ref(false);

    const toggleMenu = () => {
      showMenu.value = !showMenu.value;
    };

    return {
      portfolio,
      showMenu,
      toggleMenu
    }
  },
  metaInfo() {
    return {
      title: 'Tadas Šolys - Full Stack Developer Portfolio',
      meta: [
        { name: 'description', content: 'Explore Tadas Šolys\'s portfolio showcasing projects, skills, and expertise in full-stack development, Android, and AI training.' },
        { name: 'keywords', content: 'Tadas Šolys, Full Stack Developer, Android Developer, AI Enthusiast, Software Portfolio' },
        { name: 'author', content: 'Tadas Šolys' },
        { property: 'og:title', content: 'Tadas Šolys - Full Stack Developer Portfolio' },
        { property: 'og:description', content: 'Discover Tadas Šolys\'s projects and skills in full-stack development and AI.' },
        { property: 'og:image', content: 'https://lh3.googleusercontent.com/a/ACg8ocIr5x2zDxzkJB61l9_5IsNzeHBG3ljQFuUfxCK8NVoU9psbnYb_sA=s360-c-no' },
        { property: 'og:url', content: 'https://tadassolys.site' },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Tadas Šolys - Full Stack Developer Portfolio' },
        { name: 'twitter:description', content: 'Discover Tadas Šolys\'s projects and skills in full-stack development and AI.' },
        { name: 'twitter:image', content: 'https://lh3.googleusercontent.com/a/ACg8ocIr5x2zDxzkJB61l9_5IsNzeHBG3ljQFuUfxCK8NVoU9psbnYb_sA=s360-c-no' },
         // Pridedame kanoninę žymą
        { rel: 'canonical', href: 'https://tadassolys.site' }
      ],
      script: [
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Person",
            "name": "Tadas Šolys",
            "jobTitle": "Full Stack Developer",
            "url": "https://tadassolys.site",
            "sameAs": [
              "https://github.com/tadassolys",
              "https://www.linkedin.com/in/tadas-%C5%A0olys-8a2418328/"
            ],
            "image": "https://lh3.googleusercontent.com/a/ACg8ocIr5x2zDxzkJB61l9_5IsNzeHBG3ljQFuUfxCK8NVoU9psbnYb_sA=s360-c-no",  // Replace with your image URL
            "description": "Portfolio showcasing Tadas Šolys's projects and expertise in full-stack development, Android, and AI training.",
            "alumniOf": { "@type": "CollegeOrUniversity", "name": "SMK, MRU" },
            "knowsAbout": ["Java", "Kotlin", "JavaScript", "Node.js", "SpringBoot", "Laravel", "MySQL", "Docker", "AI", "Machine Learning"],
            "workExample": [
              {
                "@type": "CreativeWork",
                "headline": "Landmark Recognition Android App",
                "description": "Android app for landmark recognition using machine learning.",
                "creator": "Tadas Šolys",
                "programmingLanguage": ["Kotlin", "Python", "TensorFlow"],
                "url": "https://github.com/tadassolys/Lankytinu_objektu_atpazinimas"
              },
              {
                "@type": "CreativeWork",
                "headline": "Reservation Management System",
                "description": "Real-time room and equipment reservation system built with Vue 3 and Laravel.",
                "creator": "Tadas Šolys",
                "programmingLanguage": ["Vue 3", "Laravel", "MySQL"],
                "url": "https://github.com/tadassolys/Rezervis_frontend"
              }
            ]
          })
        }
      ]
    };
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
}

body {
  background: #0f172a;
  color: #e2e8f0;
}


.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(15, 23, 42, 0.9);
  backdrop-filter: blur(10px);
  z-index: 100;
}

.nav-content {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #60a5fa;
}

.nav-links {
  display: flex;
  gap: 2rem;
}

.nav-links a {
  color: #e2e8f0;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #60a5fa;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  cursor: pointer;
  gap: 5px;
}

.hamburger .bar {
  height: 3px;
  background-color: #e2e8f0;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }
  
  .nav-links.active {
    display: block;
  }

  .hamburger {
    display: flex;
  }
}

.section-title {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

#skills {
  margin: 4rem 0;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 2rem;
}

.skill-tag {
  background: #2563eb;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.9rem;
}

#contact {
  margin: 4rem 0;
}

.contact-form {
  max-width: 500px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid #4b5563;
  background: #1e293b;
  color: #e2e8f0;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #60a5fa;
}

button {
  background: #2563eb;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background 0.3s;
}

button:hover {
  background: #1d4ed8;
}

/* Footer Styling */
#contact {
  color: #b3b3b3;
  padding: 2rem 1.5rem;
  text-align: center;
}

.footer-content {
  max-width: 600px;
  margin: 0 auto;
}

.section-title {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #f5f5f5;
}

.footer-text {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #b3b3b3;
}

.footer-contact {
  font-size: 0.875rem;
  color: #7f7f7f;
}

footer a {
  color: #0078ff;
  text-decoration: none;
  transition: color 0.3s ease;
}

footer a:hover {
  color: #005fcc;
}

/* Projects Section */
.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.project-card {
  background: #1e293b;
  border-radius: 1rem;
  padding: 1.5rem;
  transition: transform 0.3s;
  cursor: pointer;
}

.project-card:hover {
  transform: translateY(-10px);
}

.project-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.project-description {
  margin: 1rem 0;
}

.project-card ul {
  list-style-type: none;
  padding-left: 0;
}

.project-card li {
  background: #2563eb;
  color: #fff;
  padding: 0.3rem 0.6rem;
  margin: 0.3rem 0;
  border-radius: 0.5rem;
}

</style>

